import { createAction, createReducer } from '@reduxjs/toolkit'

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  count: number
  countNoteNoRead: number
  note: {
    count: number
  } | null
}

const initialState: AuthState = {
  count: 0,
  countNoteNoRead: 0,
  note: null
}

export const updateCountNoteNoRead = createAction('updateCountNoteNoRead', function (count: number) {
  return {
    payload: count
  }
})

export const setCurrentReadNote = createAction('setCurrentReadNote', function () {
  return { payload: null }
})

export const setNote = createAction('setNote', function (note: { count: number }) {
  return { payload: note }
})

const noteReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(updateCountNoteNoRead, (state, action) => {
      state.countNoteNoRead = action.payload
    })
    .addCase(setCurrentReadNote, (state) => {
      state.countNoteNoRead = state.countNoteNoRead - 1
    })
    .addCase(setNote, (state, action) => {
      state.note = action.payload
    })
})

export default noteReducer
