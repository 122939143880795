const path = {
  home: '/',
  register: '/register',
  casino: '/casino',
  chat11: '/customer',
  recharge: '/recharge',
  withdraw: '/withdraw',
  event: '/event',
  note: '/list-note',
  new_board: '/notice',
  slot: '/slot',
  list_point: '/list-point',
  /*---Battle----*/
  battle: '/battle',
  battle_sports: '/battle/sports',
  battle_minigame: '/battle/minigame',
  battle_virtual_sport: '/battle/virtual-sports',
  battle_bet_games: '/battle/bet-games',
  battle_casino: '/battle/casino',
  landing1: '/landing-1',
  move_money: '/move-money'
} as const

export const unAuthRouters = ['/', '/home', '/login', '/register']

export default path
