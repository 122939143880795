import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../stores/auth.reducer'
import commonReducer from '../stores/common.reducer'
import noteReducer from '../stores/note.reducer'

export const store = configureStore({
  reducer: { auth: authReducer, note: noteReducer, common: commonReducer }
})

/* 
  Lấy RootState và AppDispatch từ store
*/
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
