/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { config } from 'src/constants/config'
import { HttpStatusCode } from 'src/constants/httpStatusCode.enum'
import path, { unAuthRouters } from 'src/constants/path'
import { clearLS, getAccessTokenFromLS } from './auth'
import { logout } from '../stores/auth.reducer'
import { store } from './store'
import { Navigate } from 'react-router-dom'

const httpLink = createHttpLink({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  uri: ({ getContext }) => {
    const { apiName } = getContext()
    let url

    switch (apiName) {
      case 'api':
        url = config.baseUrl + '/'
        break
      case 'auth':
        url = config.baseUrl + '/auth'
        break
      case 'member':
        url = config.baseUrl + '/member'
        break
      case 'money-info':
        url = config.baseUrl + '/money-info'
        break
      case 'event':
        url = config.baseUrl + '/event'
        break
      case 'note':
        url = config.baseUrl + '/note'
        break
      case 'announcement':
        url = config.baseUrl + '/announcement'
        break
      case 'consultation':
        url = config.baseUrl + '/consultation'
        break
      case 'game-provider':
        url = config.baseUrl + '/game-provider'
        break
      case 'siteInfo':
        url = config.baseUrl + '/siteInfo'
        break
      case 'game':
        url = config.baseUrl + '/game'
        break
      case 'bet-histories':
        url = config.baseUrl + '/bet-histories'
        break
      case 'popup':
        url = config.baseUrl + '/popup'
        break
      default:
        url = ''
        break
    }

    return url
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { statusCode }: any = networkError || {}
  const isUnauthenticated = statusCode === HttpStatusCode.Unauthorized

  // token expired && api return 401 && current page is protected pages
  if (isUnauthenticated) {
    store.dispatch(logout())
    clearLS()
    if (!unAuthRouters.includes(window.location.pathname)) {
      Navigate({ to: path.home })
    }
  }

  // for dev debug, global request error will show in console
  if ((process.env.REACT_APP_NODE_ENV as string) === 'development') {
    console.log('[GraphQL error]', graphQLErrors)
    console.log('[Network error]', networkError)
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessTokenFromLS()

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  connectToDevTools: (process.env.REACT_APP_NODE_ENV as string) === 'development' ? true : false
})
