import { createAction, createReducer } from '@reduxjs/toolkit'

interface CommonState {
  isLoadingPage: boolean
}

const initialState: CommonState = {
  isLoadingPage: false
}

export const setIsLoadingPage = createAction('loading', function (isLoadingPage: boolean) {
  return {
    payload: {
      isLoadingPage: isLoadingPage
    }
  }
})

const commonReducer = createReducer(initialState, (builder) => {
  builder.addCase(setIsLoadingPage, (state, action) => {
    state.isLoadingPage = action.payload.isLoadingPage
  })
})

export default commonReducer
