import { NavLink } from 'react-router-dom'
import './landing_3.scss'
export default function Landing_3() {
  return (
    <div className='landing_3'>
      <div className='image_wrapper flex flex-col items-center justify-center'>
        <div className='w-full lg:w-[50%] 2xl:w-[40%]'>
          <div className=''>
            <img src='/assets/images/landing_3/mnm-img01.jpg' />
          </div>
          <div className=''>
            <a href='http://mnm001.com' target='_blank' className='w-[40%]'>
              <img src='/assets/images/landing_3/mnm-img02.jpg' />
            </a>
          </div>
          <div className=''>
            <img src='/assets/images/landing_3/mnm-img03.jpg' />
          </div>
        </div>
      </div>
    </div>
  )
}
